<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-layers2"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row">

      <!--Item Name-->
      <div :class="`form-group ${updating ? 'col-12' : 'col-7'}`">
        <label for="itemName" class="col-form-label">
          {{ state.form.createGroup.value ? 'Group' : 'Item' }} Name:
          <span class="text-danger">*</span>
          <FormInputHelp>Item or Group name</FormInputHelp>
        </label>
        <input
          type="text"
          class="form-control"
          id="itemName"
          :placeholder="state.form.createGroup.value ? 'Group Name' : 'Item Name'"
          v-model.trim="state.form.itemName.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.itemName.error" />
      </div>

      <!--Create group / item switch-->
      <div
        v-if="!updating"
        class="form-group col-5"
      >
        <label for="sort" class="col-form-label">
          Create As Group:
          <span class="text-danger">*</span>
          <FormInputHelp>Enable to create group</FormInputHelp>
        </label>
        <SwitchComp
          :checked="state.form.createGroup.value"
          @change="val=>state.form.createGroup.value=val"
          enable-text="Creating Group"
          disable-text="Creating Item"
        />
      </div>

      <!--Group select-->
      <transition name="fade">
        <div
          v-if="!state.form.createGroup.value && !itemIsGroup"
          class="form-group col-12"
        >
          <label for="group" class="col-form-label">
            Select Group:
            <FormInputHelp>Only groupSelect group if you want to add this item into a group</FormInputHelp>
          </label>
          <TreeSelect
            id="group"
            placeholder="Select a group item"
            v-model="state.form.groupSelect.value"
            :options="state.form.groupSelect.options"
            :multiple="false"
            :searchable="false"
            :clearable="true"
            tabindex="2"
          />
        </div>
      </transition>

      <!--Type select-->
      <div class="form-group col-12">
        <label for="type" class="col-form-label">
          Select Type:
          <span class="text-danger">*</span>
          <FormInputHelp>Select item type</FormInputHelp>
        </label>
        <TreeSelect
          id="type"
          placeholder="Select item type"
          v-model="state.form.typeSelect.value"
          :options="state.form.typeSelect.options"
          :multiple="false"
          :searchable="false"
          :clearable="true"
          :disabled="state.form.typeSelect.disabled"
          tabindex="3"
        />
        <FormErrorMsg :error="state.form.typeSelect.error" />
      </div>

      <!--Category select-->
      <div class="form-group col-12">
        <label for="category" class="col-form-label">
          Select Category:
          <span class="text-danger">*</span>
          <FormInputHelp>Select category that this item belongs to</FormInputHelp>
        </label>
        <TreeSelect
          id="category"
          placeholder="Select a Category"
          v-model="state.form.categorySelect.value"
          :options="state.form.categorySelect.options"
          :searchable="false"
          :clearable="false"
          :model-value="state.form.categorySelect.value"
          tabindex="4"
          :disabled="state.form.categorySelect.disabled"
        />
        <FormErrorMsg :error="state.form.categorySelect.error" />
      </div>

      <!--Addons Select-->
      <transition name="fade">
        <div
          v-if="!state.form.createGroup.value && !itemIsGroup"
          class="form-group col-12"
        >
          <label for="addon" class="col-form-label">
            Select Addons:
            <FormInputHelp>Add addons with this item</FormInputHelp>
          </label>
          <TreeSelect
            id="addon"
            placeholder="Select addons"
            v-model="state.form.addonSelect.value"
            :options="state.form.addonSelect.options"
            :multiple="true"
            :searchable="false"
            :clearable="true"
            tabindex="5"
          />
        </div>
      </transition>

      <!--Price-->
      <div class="form-group col-6">
        <label for="price" class="col-form-label">
          Price:
          <span class="text-danger">*</span>
          <FormInputHelp>Price with decimals</FormInputHelp>
        </label>
        <input
          type="number"
          step="0.01"
          class="form-control"
          id="price"
          placeholder="Sort position (descending)"
          v-model.number="state.form.price.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.price.error" />
      </div>

      <!--Sort-->
      <div class="form-group col-6">
        <label for="sort" class="col-form-label">
          Sort:
          <span class="text-danger">*</span>
          <FormInputHelp>Works on descending order</FormInputHelp>
        </label>
        <input
          type="number"
          class="form-control"
          id="sort"
          placeholder="Sort position (descending)"
          v-model.number="state.form.sort.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.sort.error" />
      </div>

      <!--Description-->
      <div class="form-group col-7">
        <label for="description" class="col-form-label">
          Description:
          <FormInputHelp>Works on descending order</FormInputHelp>
        </label>
        <textarea
          class="form-control"
          id="description"
          placeholder="Item description"
          v-model.trim="state.form.description.value"
          required
        ></textarea>
        <FormErrorMsg :error="state.form.description.error" />
      </div>

      <!--Status-->
      <div class="form-group col-5">
        <label for="sort" class="col-form-label">
          Status:
          <FormInputHelp>Disable to hide {{ state.form.createGroup.value ? 'group' : 'item' }}</FormInputHelp>
        </label>
        <switch-comp
          :checked="state.form.status"
          @change="val=>state.form.status=val"
          enable-text="Enabled"
          disable-text="Disabled"
        />
      </div>

    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { decimal, maxLength, minLength, minValue, required, numeric } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';

export default {
  name: 'ItemAction',
  components: { ModalComp, FormErrorMsg, FormInputHelp, SwitchComp, TreeSelect },
  emits: ['hide'],
  props: {
    item: {
      type: [Object, Boolean],
      default: false
    },
    updating: Boolean,
    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    const store = useStore();

    const itemState = store.state.menu.items;
    const categoryState = store.state.menu.categories;
    const addonState = store.state.menu.addons;

    const state = reactive({
      modal: {
        show: false,
        title: 'Create New Item',
        submitBtnText: 'Add Item'
      },
      form: {
        itemName: {
          value: '',
          error: false
        },
        createGroup: {
          value: false,
          error: false,
        },
        price: {
          value: 0.01,
          error: false
        },
        sort: {
          value: 0,
          error: false
        },
        status: true,
        description: {
          value: '',
          error: false
        },
        groupSelect: {
          value: null,
          options: computed(() => {
            const list = itemState.data.items;

            if (!list) return [];

            return list
              // exclude self && sub items
              .filter(({ is_group }) => is_group)
              .map(({ id, name }) => ({
                id,
                label: name
              }));
          }),
          error: false
        },
        typeSelect: {
          value: null,
          options: [],
          error: false,
          disabled: false
        },
        categorySelect: {
          value: null,
          options: [],
          error: false,
          disabled: false
        },
        addonSelect: {
          value: [],
          options: [],
          error: false
        },
        submitting: false
      },
    });

    // set select options start
    // state.form.groupSelect.options = computed(() => {
    //  const list = itemState.data.items;
    //
    //  if (!list) return [];
    //
    //  return list
    //    // exclude self && sub items
    //    .filter(({ is_group }) => is_group)
    //    .map(({ id, name }) => ({
    //      id,
    //      label: name
    //    }));
    // });

    state.form.categorySelect.options = computed(() => {
      const list = categoryState.data.categories;

      if (!list) return [];

      return list
        .filter(({ parent_id }) => !parent_id)
        .map(({ id, name }) => ({
          id,
          label: name
        }));
    });

    state.form.typeSelect.options = computed(() => {
      const list = itemState.data.itemTypes;

      if (!list) return [];

      return list.map(typeName => ({
        id: typeName,
        label: typeName
      }));
    });

    state.form.addonSelect.options = computed(() => {
      const list = addonState.data.addons;

      if (!list) return [];

      return list
        .filter((item) => item.status)
        .map(({ id, name }) => ({
          id,
          label: name
        }))
      ;
    });
    // set select options end

    const itemIsGroup = computed(() => !!(props.updating && props.item.is_group));

    // if creating as a group then there's no group to be selected
    watch(() => state.form.createGroup.value, (nv) => {
      if (nv) state.form.groupSelect.value = null;
    });

    // disable category & type selection when selected group
    // use categories group & type instead
    watch(() => state.form.groupSelect.value, (nv) => {

      // when group is selected
      // disable category selection
      state.form.categorySelect.disabled = !!nv;

      // disable type selection
      state.form.typeSelect.disabled = !!nv;

      // use categories type & group
      if (nv) {
        const selectedGroupCategory = itemState.data.items.find(itm => itm.id === nv);

        // select groups category
        state.form.categorySelect.value = selectedGroupCategory.category.id;

        // select groups type
        state.form.typeSelect.value = selectedGroupCategory.type;
      }

    });

    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    watch(() => props.updating, (nv) => {

      // reset the form if updating
      if (!nv) {
        // reset form
        resetForm();

        state.modal = {
          ...state.modal,
          title: 'Create New Item',
          submitBtnText: 'Add Item'
        };

        return false;
      }

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Item',
        submitBtnText: 'Save Changes'
      };

      // populate values
      state.form.createGroup.value = props.item.is_group;
      state.form.itemName.value = props.item.name;
      state.form.groupSelect.value = props.item?.group?.id;
      state.form.typeSelect.value = props.item?.type;
      state.form.categorySelect.value = props.item?.category?.id;
      state.form.price.value = props.item.price?.toFixed(2);
      state.form.sort.value = props.item.sort;
      state.form.description.value = props.item.description;
      state.form.status = (props.item.status === 1);

      if (props.item.addons) {
        state.form.addonSelect.value = props.item.addons.map(({ id }) => id);
      }
    });

    // validation start
    const validationRules = {
      form: {
        itemName: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(120),
          }
        },
        categorySelect: {
          value: {
            required
          }
        },
        typeSelect: {
          value: {
            required
          }
        },
        price: {
          value: {
            required,
            decimal,
            // mustContain: mustContain('.'), Removed Because isn't Accepting Integer value
            minValue: minValue(0.00)
          }
        },
        sort: {
          value: {
            required,
            numeric,
            minValue: minValue(0)
          }
        },
        description: {
          value: {
            maxLength: maxLength(300)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.itemName.error = errors['form.itemName.value'] || false;
          state.form.categorySelect.error = errors['form.categorySelect.value'] || false;
          state.form.typeSelect.error = errors['form.typeSelect.value'] || false;
          state.form.price.error = errors['form.price.value'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
          state.form.description.error = errors['form.description.value'] || false;
        },
        {
          'form.itemName.value': 'Item Name',
          'form.categorySelect.value': 'Category',
          'form.typeSelect.value': 'Type',
          'form.price.value': 'Price',
          'form.sort.value': 'Sort',
          'form.description.value': 'Description',
        }
      );
    });
    // validation end

    const resetForm = () => {
      state.form.itemName = {
        value: '',
        error: false
      };
      state.form.createGroup = {
        value: false,
        error: false,
      };
      state.form.price = {
        value: 0.01,
        error: false
      };
      state.form.sort = {
        value: 0,
        error: false
      };
      state.form.status = true;
      state.form.description = {
        value: '',
        error: false
      };
      state.form.groupSelect = {
        value: null,
        options: [...state.form.groupSelect.options],
        error: false
      };
      state.form.typeSelect = {
        value: null,
        options: [...state.form.typeSelect.options],
        error: false,
        disabled: false
      };
      state.form.categorySelect = {
        value: null,
        options: [...state.form.categorySelect.options],
        error: false,
        disabled: false
      };
      state.form.addonSelect = {
        value: [],
        options: [...state.form.categorySelect.options],
        error: false
      };
    };

    function handleClose () {
      emit('hide', false);
    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      const data = {
        itemName: state.form.itemName.value,
        isGroup: state.form.createGroup.value,

        // add only if its not a group
        group: state.form.createGroup.value ? null : state.form.groupSelect.value,
        addons: state.form.createGroup.value ? null : state.form.addonSelect.value,

        category: state.form.categorySelect.value,
        type: state.form.typeSelect.value,
        price: parseFloat(state.form.price.value),
        sort: state.form.sort.value,
        description: state.form.description.value,
        status: state.form.status
      };

      state.form.submitting = true;

      try {

        if (props.updating) {
          await store.dispatch('menu/updateItem', { id: props.item.id, data });
        } else {
          await store.dispatch('menu/createItem', data);
        }

        Toaster.successAlt({
          title: `Item ${props.updating ? 'Updated' : 'Created'}`,
          message: `'${data.itemName}' has been ${props.updating ? 'updated' : 'created'}`
        });

        // Reset form
        resetForm();

        // close the modal
        handleClose();

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.itemName.error = ex(err.itemName);
        });

      }

      state.form.submitting = false;
    }

    return {
      state,
      itemIsGroup,
      handleClose,
      handleSubmit,
    };
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
